.about-container {
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 20px;
  gap: 20px;
  align-items: center;
  /* Vertically center the content */
}

.about-column1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image {
  max-width: 100%;
  height: auto;
  max-height: 500px;
  /* Set a max height for the image */
}

.about-column2 {
  padding-right: 100px;
  /* Right padding for the second column */
}

.about-text {
  font-size: 18px;
  line-height: 1.6;
  color: #000000;
  /* Regular black text */
  margin: 10px 0;
}

.about-highlight {
  font-weight: bold;
}

.about-orange {
  color: #ffad00;
  /* Orange text */
}

.about-grey {
  color: black;
  /* Grey text */
}

@media screen and (max-width: 768px) {
  .about-container {
    display: inherit;
  }

  .about-column2 {
    padding: 0;
    text-align: center;
  }
}
