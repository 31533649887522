.discounts-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: "Century Gothic", sans-serif;
  }
  
  .discounts-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .discounts-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .discounts-header h1 {
    font-size: 32px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .discounts-subtitle {
    color: #666;
    font-size: 18px;
  }
  
  .active-promotions,
  .loyalty-program,
  .bulk-discounts,
  .special-offers,
  .discounts-footer {
    margin-bottom: 60px;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .active-promotions h2,
  .loyalty-program h2,
  .bulk-discounts h2,
  .special-offers h2 {
    color: #ffad00;
    font-size: 24px;
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f4f4f4;
  }
  
  .promotions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 30px;
  }
  
  .promotion-card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    background-color: white;
  }
  
  .promotion-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
  
  .new-promotion {
    border: 2px solid #ffad00;
  }
  
  .new-badge {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #ffad00;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 20px;
    z-index: 1;
  }
  
  .promotion-image {
    height: 200px;
    overflow: hidden;
  }
  
  .promotion-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .promotion-card:hover .promotion-image img {
    transform: scale(1.05);
  }
  
  .promotion-content {
    padding: 20px;
  }
  
  .promotion-content h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .promotion-content p {
    margin-bottom: 15px;
    color: #555;
    line-height: 1.5;
  }
  
  .promo-code {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .promo-code code {
    background-color: #ffad00;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-family: monospace;
    font-weight: bold;
    font-size: 16px;
  }
  
  .expiry-date {
    font-size: 14px;
    color: #777;
    font-style: italic;
  }
  
  .loyalty-content {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  
  .loyalty-info {
    flex: 1;
  }
  
  .loyalty-info p {
    margin-bottom: 15px;
    color: #555;
    line-height: 1.6;
  }
  
  .loyalty-info ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .loyalty-info li {
    margin-bottom: 10px;
    color: #555;
  }
  
  .loyalty-image {
    flex: 1;
    max-width: 400px;
  }
  
  .loyalty-image img {
    width: 100%;
    border-radius: 10px;
  }
  
  .discount-table {
    margin: 20px 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .table-row {
    display: flex;
  }
  
  .table-row.header {
    background-color: #ffad00;
    color: white;
    font-weight: bold;
  }
  
  .table-cell {
    flex: 1;
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  
  .table-row:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table-row:last-child .table-cell {
    border-bottom: none;
  }
  
  .bulk-note {
    margin-top: 20px;
    font-style: italic;
    color: #666;
  }
  
  .offers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .offer-card {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    border-left: 4px solid #ffad00;
    transition: transform 0.3s ease;
  }
  
  .offer-card:hover {
    transform: translateY(-5px);
  }
  
  .offer-card h3 {
    color: #333;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }
  
  .offer-card p {
    color: #555;
    line-height: 1.5;
    margin: 0;
  }
  
  .discounts-footer {
    background-color: #f6f2ea;
  }
  
  .discounts-footer h3 {
    color: #333;
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .discounts-footer ul {
    padding-left: 20px;
  }
  
  .discounts-footer li {
    margin-bottom: 10px;
    color: #555;
    line-height: 1.6;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .promotions-grid,
    .offers-grid {
      grid-template-columns: 1fr;
    }
    
    .loyalty-content {
      flex-direction: column;
    }
    
    .loyalty-image {
      max-width: 100%;
      margin-top: 20px;
    }
    
    .discounts-header h1 {
      font-size: 28px;
    }
    
    .active-promotions,
    .loyalty-program,
    .bulk-discounts,
    .special-offers,
    .discounts-footer {
      padding: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .discounts-container {
      padding: 20px 15px;
    }
    
    .promotion-card {
      min-width: 280px;
    }
    
    .discount-table {
      font-size: 14px;
    }
    
    .table-cell {
      padding: 8px;
    }
    
    .discounts-header h1 {
      font-size: 24px;
    }
    
    .promo-code {
      flex-direction: column;
      align-items: flex-start;
    }
    
    .promo-code code {
      margin-top: 5px;
    }
  }