/* Mobile-only styles for Room Layout */
@media screen and (max-width: 768px) {
  /* Mobile container */
  .roomlayout-container.mobile {
    display: flex;
    flex-direction: column;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
  }

  /* Hide desktop elements when in mobile view */
  .roomlayout-container.mobile .roomlayout-left-column,
  .roomlayout-container.mobile .roomlayout-right-column {
    display: none;
  }

  /* Mobile steps indicator */
  .mobile-steps-indicator {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .mobile-steps-indicator .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    padding: 10px;
  }

  .mobile-steps-indicator .step span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd;
    color: #666;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .mobile-steps-indicator .step p {
    font-size: 12px;
    margin: 0;
    color: #666;
  }

  .mobile-steps-indicator .step.active span {
    background-color: #ffad00;
    color: white;
  }

  .mobile-steps-indicator .step.active p {
    color: #ffad00;
    font-weight: bold;
  }

  .mobile-steps-indicator .step.completed span {
    background-color: #4caf50;
    color: white;
  }

  /* Connect steps with line */
  .mobile-steps-indicator .step:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 35px;
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: #ddd;
    z-index: -1;
  }

  .mobile-steps-indicator .step.completed::after {
    background-color: #4caf50;
  }

  /* Mobile step content */
  .mobile-step-content {
    padding: 15px;
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
  }

  .mobile-step-content h3 {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
  }

  /* Size selection grid */
  .mobile-sizes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }

  .mobile-size-button {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-size-button.selected {
    border-color: #ffad00;
    background-color: #fff8e5;
  }

  .mobile-size-button .size-name {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .mobile-size-button .size-price {
    color: #666;
  }

  /* Photo upload area */
  .mobile-upload-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .mobile-upload-button {
    background-color: #ffad00;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 16px 20px;
    font-size: 16px;
    width: 100%;
    max-width: 250px;
    margin-bottom: 20px;
    height: 50px;
  }

  .mobile-photo-preview {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .mobile-photo-preview img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  /* Room preview */
  .mobile-room-preview {
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    border-radius: 8px;
  }

  .mobile-room-preview canvas {
    max-width: 100%;
    height: auto !important;
  }

  /* Order summary */
  .mobile-order-summary {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .mobile-order-summary p {
    margin: 5px 0;
  }

  /* Navigation buttons */
  .mobile-step-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 10px 0;
  }

  .mobile-back-button,
  .mobile-next-button,
  .mobile-order-button {
    padding: 14px 20px;
    border-radius: 8px;
    font-size: 16px;
    border: none;
    font-weight: bold;
  }

  .mobile-back-button {
    background-color: #f1f1f1;
    color: #333;
    height: 45px;
  }

  .mobile-next-button {
    background-color: #ffad00;
    color: white;
    height: 45px;
  }

  .mobile-order-button {
    background-color: #4caf50;
    color: white;
    height: 45px;
  }

  .mobile-next-button:disabled,
  .mobile-order-button:disabled {
    background-color: #cccccc;
    color: #666;
  }

  /* Adjustments for small screens */
  @media screen and (max-width: 350px) {
    .mobile-sizes-grid {
      grid-template-columns: 1fr;
    }
    
    .mobile-steps-indicator .step span {
      width: 25px;
      height: 25px;
      font-size: 12px;
    }
    
    .mobile-steps-indicator .step p {
      font-size: 10px;
    }
    
    .mobile-back-button,
    .mobile-next-button,
    .mobile-order-button {
      padding: 12px 15px;
      font-size: 14px;
    }
  }

  /* Fix for iOS specific issues */
  @supports (-webkit-touch-callout: none) {
    .mobile-upload-button {
      -webkit-appearance: none;
      border-radius: 8px;
    }
    
    .mobile-step-navigation {
      padding-bottom: 25px; /* Extra padding for iOS safe area */
    }
  }
}