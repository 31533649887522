.order-confirmation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  padding: 20px;
}

.order-confirmation-content {
  max-width: 600px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.confirmation-logo {
  width: 150px;
  margin-bottom: 20px;
}

.confirmation-title {
  color: #ffad00;
  font-size: 28px;
  margin-bottom: 20px;
}

.confirmation-message {
  margin-bottom: 25px;
}

.confirmation-message p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #333;
}

.additional-info {
  margin-bottom: 25px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.additional-info p {
  font-weight: 600;
  margin-bottom: 15px;
}

.contact-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-link img {
  width: 40px;
  transition: transform 0.3s ease;
}

.contact-link img:hover {
  transform: scale(1.1);
}

.redirect-info {
  margin-top: 25px;
  font-size: 16px;
  color: #555;
}

.countdown-timer {
  font-weight: bold;
  font-size: 18px;
  color: #ffad00;
}

.progress-container {
  margin: 15px auto;
  width: 80%;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #ffad00;
  border-radius: 4px;
  transition: width 1s linear;
}

.home-button {
  margin-top: 15px;
  padding: 12px 24px;
  background-color: #ffad00;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 45px;
}

.home-button:hover {
  background-color: #e69a00;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .confirmation-title {
    font-size: 24px;
  }
  
  .confirmation-message p {
    font-size: 16px;
  }
  
  .order-confirmation-content {
    padding: 20px;
  }
}