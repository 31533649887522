.faq-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .faq-title {
    text-align: center;
    font-size: 32px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .faq-subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 40px;
    color: #666;
  }
  
  .faq-items {
    margin-bottom: 50px;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border: 1px solid #eee;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
  }
  
  .faq-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .faq-question h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333;
  }
  
  .faq-question.active {
    background-color: #ffad00;
  }
  
  .faq-question.active h3 {
    color: white;
  }
  
  .faq-icon {
    font-size: 24px;
    font-weight: bold;
    transition: transform 0.3s ease;
  }
  
  .faq-question.active .faq-icon {
    color: white;
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    background-color: #f9f9f9;
    padding: 0 20px;
  }
  
  .faq-answer.active {
    max-height: 500px;
    padding: 20px;
    border-top: 1px solid #eee;
  }
  
  .faq-answer p {
    margin: 0;
    line-height: 1.6;
    color: #555;
  }
  
  .faq-contact-section {
    text-align: center;
    background-color: #f6f2ea;
    padding: 30px;
    border-radius: 10px;
  }
  
  .faq-contact-section h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .faq-contact-section p {
    margin-bottom: 20px;
    color: #555;
  }
  
  .faq-contact-methods {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .faq-contact-link {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    border-radius: 30px;
    text-decoration: none;
    color: #333;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .faq-contact-link:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .faq-contact-link img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .faq-title {
      font-size: 28px;
    }
    
    .faq-subtitle {
      font-size: 16px;
    }
    
    .faq-question h3 {
      font-size: 16px;
    }
    
    .faq-contact-methods {
      flex-direction: column;
    }
  }
  
  @media (max-width: 480px) {
    .faq-container {
      padding: 20px 15px;
    }
    
    .faq-title {
      font-size: 24px;
    }
    
    .faq-question {
      padding: 15px;
    }
    
    .faq-answer.active {
      padding: 15px;
    }
  }