.legal-page-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    min-height: 80vh;
  }
  
  .legal-sidebar {
    width: 280px;
    padding-right: 30px;
    position: sticky;
    top: 20px;
    align-self: flex-start;
  }
  
  .legal-sidebar h2 {
    color: #333;
    margin-bottom: 20px;
    font-size: 22px;
  }
  
  .legal-navigation {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .legal-navigation li {
    margin-bottom: 12px;
  }
  
  .legal-navigation a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: block;
    padding: 8px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  
  .legal-navigation a:hover {
    background-color: #f4bf4f;
    color: white;
  }
  
  .legal-navigation li.active a {
    background-color: #ffad00;
    color: white;
    font-weight: 500;
  }
  
  .legal-content {
    flex: 1;
    padding: 0 20px;
  }
  
  .legal-content section {
    margin-bottom: 40px;
    display: none;
  }
  
  .legal-content section.active {
    display: block;
    animation: fadeIn 0.5s ease;
  }
  
  .legal-content h2 {
    color: #ffad00;
    margin-bottom: 20px;
    font-size: 26px;
  }
  
  .legal-content h3 {
    color: #333;
    margin: 25px 0 15px;
    font-size: 20px;
  }
  
  .legal-content p, .legal-content li {
    line-height: 1.6;
    color: #444;
    margin-bottom: 15px;
  }
  
  .legal-content ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .legal-page-container {
      flex-direction: column;
      padding: 15px;
    }
    
    .legal-sidebar {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
      padding-right: 0;
    }
    
    .legal-navigation {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
    
    .legal-navigation li {
      margin-bottom: 8px;
      min-width: 45%;
    }
    
    .legal-navigation a {
      padding: 8px;
      font-size: 14px;
      text-align: center;
    }
    
    .legal-content {
      padding: 0;
    }
    
    .legal-content h2 {
      font-size: 22px;
    }
    
    .legal-content h3 {
      font-size: 18px;
    }
    
    .legal-content p, .legal-content li {
      font-size: 15px;
    }
  }
  
  /* Small mobile devices */
  @media (max-width: 480px) {
    .legal-page-container {
      padding: 10px;
    }
  
    .legal-navigation li {
      min-width: 100%;
    }
    
    .legal-content h2 {
      font-size: 20px;
    }
    
    .legal-content h3 {
      font-size: 16px;
    }
    
    .legal-content section {
      margin-bottom: 30px;
    }
  }