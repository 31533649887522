/* Mobile-only styles for PriceCalculator */
@media screen and (max-width: 768px) {
  /* Hide the original calculator when in mobile view */
  .price-calculator.mobile .calculator-top,
  .price-calculator.mobile .calculator-bottom {
    display: none;
  }

  /* Mobile steps indicator */
  .mobile-steps-indicator {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 15px 10px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .mobile-steps-indicator .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 0 0 auto;
    margin-right: 15px;
    padding: 0 5px;
  }

  .mobile-steps-indicator .step span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ddd;
    color: #666;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .mobile-steps-indicator .step p {
    font-size: 12px;
    margin: 0;
    color: #666;
    text-align: center;
    white-space: nowrap;
  }

  .mobile-steps-indicator .step.active span {
    background-color: #ffad00;
    color: white;
  }

  .mobile-steps-indicator .step.active p {
    color: #ffad00;
    font-weight: bold;
  }

  .mobile-steps-indicator .step.completed span {
    background-color: #4caf50;
    color: white;
  }

  /* Mobile panel content */
  .mobile-panel-content {
    padding: 15px;
  }

  .mobile-panel {
    min-height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
  }

  .mobile-panel h3 {
    font-size: 18px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile-panel .info-hint-container {
    margin-left: 8px;
  }

  /* Style selection grid */
  .mobile-styles-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .mobile-style-item {
    padding: 15px;
    border: 2px solid #eee;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .mobile-style-item.selected {
    border-color: #ffad00;
    background-color: #fff8e5;
  }

  .mobile-style-item .style-name {
    font-weight: bold;
    margin-bottom: 5px;
  }

  /* Size selection grid */
  .mobile-sizes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }

  .mobile-size-item {
    padding: 15px;
    border: 2px solid #eee;
    border-radius: 8px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .mobile-size-item.selected {
    border-color: #ffad00;
    background-color: #fff8e5;
    font-weight: bold;
  }

  /* People number options */
  .mobile-people-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }

  .mobile-people-option {
    padding: 15px 10px;
    border: 2px solid #eee;
    border-radius: 8px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .mobile-people-option.selected {
    border-color: #ffad00;
    background-color: #fff8e5;
    font-weight: bold;
  }

  /* Objects list */
  .mobile-objects-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .mobile-object-item {
    padding: 15px;
    border: 2px solid #eee;
    border-radius: 8px;
    background-color: white;
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .mobile-object-item.selected {
    border-color: #ffad00;
    background-color: #fff8e5;
  }

  .mobile-object-item .object-name {
    display: flex;
    align-items: center;
  }

  .mobile-object-item .info-hint-container {
    margin-left: 5px;
  }

  /* Extras list */
  .mobile-extras-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .mobile-extra-item {
    padding: 15px;
    border: 2px solid #eee;
    border-radius: 8px;
    background-color: white;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  .mobile-extra-item.selected {
    border-color: #ffad00;
    background-color: #fff8e5;
  }

  .mobile-extra-item .extra-name {
    display: flex;
    align-items: center;
  }

  /* Photo upload area */
  .mobile-upload-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .mobile-upload-button {
    background-color: #ffad00;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 16px 20px;
    font-size: 16px;
    width: 100%;
    max-width: 250px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    cursor: pointer;
  }

  .mobile-photo-preview {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .mobile-photo-preview img {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  /* Review step */
  .mobile-review-image {
    width: 200px;
    height: auto;
    object-fit: cover;
    margin: 0 auto 20px;
    display: block;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }

  .mobile-order-details {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .mobile-order-detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

  .mobile-order-detail .detail-label {
    font-weight: bold;
    color: #333;
  }

  .mobile-order-price {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 2px solid #ddd;
  }

  .mobile-order-price .price-label {
    font-weight: bold;
  }

  .mobile-order-price .price-value {
    font-weight: bold;
    color: #ffad00;
  }

  .price-disclaimer {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
    line-height: 1.4;
  }

  /* Navigation buttons */
  .mobile-step-navigation,
  .mobile-step-actions {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 10px 0;
  }

  .mobile-step-actions {
    flex-wrap: wrap;
    gap: 10px;
  }

  .mobile-back-button,
  .mobile-next-button,
  .mobile-clear-button,
  .mobile-order-button {
    padding: 14px 20px;
    border-radius: 8px;
    font-size: 16px;
    border: none;
    font-weight: bold;
    min-width: 100px;
  }

  .mobile-back-button {
    background-color: #f1f1f1;
    color: #333;
  }

  .mobile-next-button {
    background-color: #ffad00;
    color: white;
  }

  .mobile-clear-button {
    background-color: #f04e23;
    color: white;
    flex: 1;
  }

  .mobile-order-button {
    background-color: #4caf50;
    color: white;
    flex: 1;
  }

  .mobile-next-button:disabled,
  .mobile-order-button:disabled {
    background-color: #cccccc;
    color: #666;
  }

  .mobile-warning {
    color: #f44336;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
  }

  /* Adjustments for small screens */
  @media screen and (max-width: 350px) {
    .mobile-sizes-grid {
      grid-template-columns: 1fr;
    }
    
    .mobile-people-options {
      grid-template-columns: repeat(2, 1fr);
    }
    
    .mobile-back-button,
    .mobile-next-button,
    .mobile-clear-button,
    .mobile-order-button {
      padding: 12px 15px;
      font-size: 14px;
    }
  }

  /* Fix for iOS specific issues */
  @supports (-webkit-touch-callout: none) {
    .mobile-upload-button {
      -webkit-appearance: none;
      border-radius: 8px;
    }
    
    .mobile-step-navigation,
    .mobile-step-actions {
      padding-bottom: 25px; /* Extra padding for iOS safe area */
    }
  }
}