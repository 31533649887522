.delivery-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: "Century Gothic", sans-serif;
  }
  
  .delivery-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .delivery-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .delivery-header h1 {
    font-size: 32px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .delivery-subtitle {
    color: #666;
    font-size: 18px;
  }
  
  .delivery-section {
    margin-bottom: 50px;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .delivery-section h2 {
    color: #ffad00;
    font-size: 24px;
    margin-bottom: 25px;
    padding-bottom: 10px;
    border-bottom: 2px solid #f4f4f4;
  }
  
  .delivery-option, .payment-option {
    display: flex;
    margin-bottom: 30px;
    align-items: flex-start;
  }
  
  .delivery-icon, .payment-icon, .timeline-icon {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 50%;
    background-color: #f4f4f4;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }
  
  .delivery-icon.nova-poshta {
    background-color: #f9d342;
  }
  
  .delivery-icon.ukrposhta {
    background-color: #ffad00;
  }
  
  .delivery-icon.meest {
    background-color: #e4e4e4;
  }
  
  .delivery-icon.courier {
    background-color: #f9d342;
  }
  
  .delivery-icon.pickup {
    background-color: #ffad00;
  }
  
  .payment-icon.card {
    background-color: #f9d342;
  }
  
  .payment-icon.cod {
    background-color: #ffad00;
  }
  
  .payment-icon.cash {
    background-color: #f9d342;
  }
  
  .timeline-icon.production {
    background-color: #f9d342;
  }
  
  .timeline-icon.shipping {
    background-color: #ffad00;
  }
  
  .timeline-icon.delivery {
    background-color: #f9d342;
  }
  
  .delivery-details, .payment-details, .timeline-content {
    flex: 1;
  }
  
  .delivery-details h3, .payment-details h3, .timeline-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .delivery-details p, .payment-details p, .timeline-content p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .delivery-note, .payment-note {
    font-style: italic;
    color: #777;
    font-size: 14px;
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 3px solid #ffad00;
    margin-top: 10px;
  }
  
  .timeline {
    padding-left: 20px;
  }
  
  .timeline-item {
    position: relative;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
  }
  
  .timeline-item:before {
    content: '';
    position: absolute;
    left: 30px;
    top: 60px;
    bottom: -30px;
    width: 2px;
    background-color: #f4f4f4;
  }
  
  .timeline-item:last-child:before {
    display: none;
  }
  
  .timeline-content ul {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .timeline-content li {
    margin-bottom: 5px;
    color: #555;
  }
  
  .delivery-notes {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
    border-left: 5px solid #ffad00;
  }
  
  .delivery-notes h2 {
    color: #333;
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  .delivery-notes ul {
    padding-left: 20px;
  }
  
  .delivery-notes li {
    margin-bottom: 10px;
    color: #555;
    line-height: 1.6;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .delivery-option, .payment-option {
      flex-direction: column;
    }
    
    .delivery-icon, .payment-icon, .timeline-icon {
      margin-bottom: 15px;
      margin-right: 0;
    }
    
    .timeline-item {
      flex-direction: column;
    }
    
    .timeline-item:before {
      left: 30px;
      top: 60px;
      bottom: -30px;
    }
    
    .delivery-section {
      padding: 20px;
    }
    
    .delivery-header h1 {
      font-size: 28px;
    }
    
    .delivery-subtitle {
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .delivery-container {
      padding: 20px 15px;
    }
    
    .delivery-section {
      padding: 15px;
    }
    
    .delivery-header h1 {
      font-size: 24px;
    }
    
    .delivery-section h2 {
      font-size: 20px;
    }
    
    .delivery-details h3, .payment-details h3, .timeline-content h3 {
      font-size: 18px;
    }
    
    .delivery-note, .payment-note {
      font-size: 13px;
    }
  }